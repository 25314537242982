import React from 'react';
import "./contact.css";

const Contact = () => {
  const btnHandle = () => {
    alert("Site is under construction. Please Email to 'rabiulkhanofficial@gmail.com'.Will Response Soon.")
  }
  return (
    <section className="contact container section" id="contact">
      <h2 className="section__title">Get in touch</h2>

      <div className="contact__container grid">
        <div className="contact__info">
          <h3 className="contact__title">Let's talk about everything!</h3>
          <p className="contact__details">Don't like forms? Send me an email - rabiulkhanofficial@gmail.com</p>
        </div>

        <form action="" className="contact__form">
          <div className="contact__form-group">
            <div className="contact__form-div">
              <input type="text" className="contact__form-input" placeholder='Insert your name' />
            </div>

            <div className="contact__form-div">
              <input type="email" className="contact__form-input" placeholder='Insert your email' />
            </div>
          </div>

          <div className="contact__form-div">
              <input type="text" className="contact__form-input" placeholder='Insert your subject' />
            </div>

            <div className="contact__form-div contact__form-area">
              <textarea 
                name="" 
                id = "" 
                cols="30" 
                rows="10" 
                className="contact__form-input" 
                placeholder='write your message' 
                ></textarea>
            </div>

            <button className="btn" onClick={btnHandle}>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact;
