import React from 'react'

const HeaderSocials = () => {
  return (
    <div className="home__socials">
        <a href="https://github.com/rabiulkhan07" className="home__social-link" target='_blank'>
            <i class="fa-brands fa-github"></i>
        </a>

        <a href="https://www.youtube.com/@rabiulkhan1397/playlists" className="home__social-link" target='_blank'>
            <i class="fa-brands fa-youtube"></i>
        </a>

        <a href="https://www.linkedin.com/in/rabiul-khan-2a2b8496/" className="home__social-link" target='_blank'>
            <i class="fa-brands fa-linkedin"></i>
        </a>

        {/* <a href="" className="home__social-link" target='_blank'>
            <i class="fa-brands fa-dribbble"></i>
        </a>

        <a href="" className="home__social-link" target='_blank'>
            <i class="fa-brands fa-pinterest"></i>
        </a> */}
    </div>
  )
}

export default HeaderSocials
