import React from 'react';
import "./home.css";

import Me from '../../assets/image.png';
import Logo from '../../assets/logo-rk.png';
import HeaderSocials from './HeaderSocials';
import ScrollDown from './ScrollDown';

const Home = () => {
  return (
    <section className="home container" id="home">
      <div className="intro">
        <img src={Logo} alt="" className="home__img" style={{height:"350px"}} />
        <h1 className="home__name">Rabiul Khan</h1>
        <span className="home__education">I'm a Full Stack Developer.</span>

        <HeaderSocials />

        <a href="#contact" className="btn">Contact</a>

        <ScrollDown />
      </div>
    </section>
  )
}

export default Home
