import React from 'react';
import "./services.css";



import Java from '../../assets/desktop.png';
import PHP from '../../assets/web.png';
import Python from '../../assets/courses.png';

import AdsenseCompo from './../adsense/AdsenseCompo';

const Services = () => {
  const data = [
    {
      id : 1,
      image : PHP,
      title : "Web Development",
      description : "We offer you any kind of business/indivual web based system.Feel free to contact. ",
    },
    {
      id : 2,
      image : Java,
      title : "Desktop Software",
      description : "We offer you any kind of business/indivual Desktop based system.Feel free to contact.",
    },
    {
      id : 3,
      image : Python,
      title : "Courses",
      description : "We offer you Web design & development, Core PHP/java/python, Django, Swing  online courses. H.S.C ICT online batch will start soon.",
    }
  ]
  return (
    <>
    <section className="services container section" id='services'>
      <h2 className="section__title">Services</h2>

      <div className="services__container grid">
        {data.map(({id,image,title,description}) => {
          return (
            <a href="https://rabiulkhan.com/services" className="services__card">
            <div  key={id}>
              <img src={image} alt="" className="services__img" style={{height:"150px"}} />

              <h3 className="services__title">{title}</h3>

              <p className="services__description">{description}</p>
            </div>
            </a>
          )
        })}
      </div>
    </section>
    <AdsenseCompo />
    </>
  )
}

export default Services
