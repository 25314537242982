import React from 'react';
import "./about.css";
import Me from '../../assets/image.png';
import Logo from '../../assets/logo-rk.png';
import AboutBox from './AboutBox';
import AdsenseCompo from './../adsense/AdsenseCompo';

const About = () => {
  return (
    <section className="about container section" id='about'>

      <AdsenseCompo />

      <h2 className="section__title">About Me</h2>

      <div className="about__container grid">
        <img src={Logo} alt="" className="about__image" style={{height:"250px"}} />

        <div className="about__data grid">
          <div className="about__info">
            <p className="about__description">
              I am working on software industry since 2014 and developed many web/Desktop based system which is live 
              right now. I have developed Broadcast Automation System with automated billing using open source playout server 
              which is running on a reknown TV station in Bangladesh.
            </p>
            {/* <a href="#contact" className="btn">Download CV</a> */}
            <a href="#contact" className="btn">Contact</a>
          </div>

          <div className="about__skills grid">
            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">Python</h3>
                <span className="skills__number">80%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage development"></span>
              </div>
            </div>

            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">Django</h3>
                <span className="skills__number">80%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage ui_design"></span>
              </div>
            </div>

            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">ReactJS</h3>
                <span className="skills__number">70%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage photography"></span>
              </div>
            </div>

            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">JAVA</h3>
                <span className="skills__number">70%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage java"></span>
              </div>
            </div>

            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">PHP</h3>
                <span className="skills__number">80%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage php"></span>
              </div>
            </div>

            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">MySQl</h3>
                <span className="skills__number">80%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage mysql"></span>
              </div>
            </div>

            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">PostgreSQl</h3>
                <span className="skills__number">70%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage psql"></span>
              </div>
            </div>

          </div>
        </div>
      </div>

      <AboutBox />
    </section>
  )
}

export default About
