import Work1 from '../../assets/desktop.jpeg';
import Work2 from '../../assets/web.png';

import Archive from '../../assets/archive.png';
import Rundown from '../../assets/rundown.png';
import Scheduler from '../../assets/img/scheduler-1.jpg';
import Billing from '../../assets/img/billing.jpg';
import Online_gfx from '../../assets/img/online-gfx.jpg';
import Razuna from '../../assets/img/razuna.jpg';
import Aston from '../../assets/img/aston.jpg';
import Feed from '../../assets/img/feed-formulation.jpg';
import Time from '../../assets/img/time-tracker-2.jpg';

const Menu = [
    {
        id : 1,
        image : Billing,
        title : "Billing",
        category : "Web",
    },
    {
        id : 2,
        image : Online_gfx,
        title : "Online Graphics",
        category : "Web",
    },
    {
        id : 3,
        image : Razuna,
        title : "Media Asset Management (MAM) - Open source",
        category : "Web",
    },
    {
        id : 4,
        image : Aston,
        title : "Aston System",
        category : "Web",
    },
    {
        id : 5,
        image : Feed,
        title : "Feed Formulation",
        category : "Web",
    },
    {
        id : 6,
        image : Time,
        title : "Time tracking",
        category : "Web",
    },
    {
        id : 7,
        image : Archive,
        title : "Media Archive System",
        category : "Desktops",
    },
    {
        id : 8,
        image : Rundown,
        title : "Rundown",
        category : "Desktop",
    },
    {
        id : 9,
        image : Scheduler,
        title : "Scheduler",
        category : "Desktop",
    },
    
]

export default Menu