import React from 'react';
import "./blog.css";
import Image from '../../assets/coming.png';

const Blog = () => {
  return (
    <section className="blog container section" id="blog">
      <h2 className="section__title">Latest Posts</h2>

      <div className="blog__container grid">
        <div className="blog__card">
          <div className="blog__thumb">
            <a href='#'><span className="blog__category">Reviews</span></a>
            <a href='#'><img src={Image} alt="" className="blog__img" style={{height:"150px"}} /></a>
          </div>
          <div className="blog_details">
            <h3 className="blog__title">Stay Tuned</h3>
            <div className="blog__meta">
              <span>March, 2024</span>
              <span className="blog__dot">.</span>
              <span>Rabiul Khan</span>
            </div>
          </div>
        </div>

        <div className="blog__card">
          <div className="blog__thumb">
            <a href='#'><span className="blog__category">Tutorial</span></a>
            <a href='#'><img src={Image} alt="" className="blog__img" style={{height:"150px"}} /></a>
          </div>
          <div className="blog_details">
            <h3 className="blog__title">Stay Tuned</h3>
            <div className="blog__meta">
              <span>March, 2024</span>
              <span className="blog__dot">.</span>
              <span>Rabiul Khan</span>
            </div>
          </div>
        </div>

        <div className="blog__card">
          <div className="blog__thumb">
            <a href='#'><span className="blog__category">Business</span></a>
            <a href='#'><img src={Image} alt="" className="blog__img" style={{height:"150px"}} /></a>
          </div>
          <div className="blog_details">
            <h3 className="blog__title">Stay Tuned</h3>
            <div className="blog__meta">
              <span>March, 2024</span>
              <span className="blog__dot">.</span>
              <span>Rabiul Khan</span>
            </div>
          </div>
        </div>
        
      </div>
      <a href="https://rabiulkhan.com/blog" className="btn blog__btn">Read More ...</a>
    </section>
  )
}

export default Blog
