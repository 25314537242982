import React, { useState } from 'react';
import "./courses.css";
import Course from './Data';
import Modal from './Modal'; 
import Java from '../../assets/img/java.jpg';
import PHP from '../../assets/img/php.jpg';
import Python from '../../assets/img/python.jpg';

const Courses = () => {

    const data = [
        {
          id : 1,
          image : Java,
          title : "Java",
          description : "Details Click Here. ",
        },
        {
          id : 2,
          image : PHP,
          title : "PHP",
          description : "Details Click Here. ",
        },
        {
          id : 3,
          image : Python,
          title : "Python",
          description : "Details Click Here. ",
        },
        {
          id : 4,
          image : PHP,
          title : "Web Development",
          description : "Details Click Here. ",
        },
        {
          id : 5,
          image : Python,
          title : "Web Design",
          description : "Details Click Here. ",
        }
      ]
      return (
        <section className="services container section" id='services'>
          <h2 className="section__title">Courses</h2>
    
          <div className="services__container grid"> 
            {data.map(({id,image,title,description}) => {
              return (
                <a href="https://rabiulkhan.com/courses" target='_blank' className="services__card">
                <div  key={id}>
                  <img src={image} alt="" className="services__img" style={{height:"150px"}} />
    
                  <h3 className="services__title">{title}</h3>
    
                  <p className="services__description">{description}</p>
                </div>
                </a>
              )
            })}
          </div>
        </section>
      )
}

export default Courses
