import React, { Component  } from 'react';

class AdsenseCompo extends Component {

    componentDidMount() {
     (window.adsbygoogle = window.adsbygoogle || []).push({})
    }

   render () {
    return(
        <div>
        <ins className = "adsbygoogle"
                style = { {display:"inline-block",width:"728px",height:"90px"} }
                data-ad-client = "ca-pub-7884037025290208"
                ></ins>
        </div>)
    }
}

export default AdsenseCompo;