const Data = [
    {
        id : 1,
        category : "job",
        icon : "icon-briefcase",
        year : "2019 - Present",
        title : "Sysnova Information System Ltd",
        desc : "Senior Software Engineer"
    },
    {
        id : 2,
        category : "job",
        icon : "icon-briefcase",
        year : "2015 - 2019",
        title : "Sysnova Information System Ltd",
        desc : "Software Engineer"
    },
    {
        id : 3,
        category : "job",
        icon : "icon-briefcase",
        year : "2014 - 2015",
        title : "ActiveIT Zone",
        desc : "Software Engineer"
    },
    {
        id : 4,
        category : "education",
        icon : "icon-graduation",
        year : "2010 - 2014",
        title : "Ahsanullah University Of Science & Technology",
        desc : "B.Sc in Computer Science & Engineering"
    },
    {
        id : 5,
        category : "education",
        icon : "icon-graduation",
        year : "2007 - 2009",
        title : "Magura H.S.S. College, Magura",
        desc : "H.S.C"
    },
    {
        id : 6,
        category : "education",
        icon : "icon-graduation",
        year : "2002 - 2007",
        title : "Magura Govt. High School",
        desc : "S.S.C"
    },
]

export default Data
